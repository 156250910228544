import React from 'react';
import { Layout, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import screenshotMain from '../assets/screenshot_main.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function CutPricing() {
  return (
    <Content className={styles.cut}>
      <Row style={{ zIndex: 10, position: 'relative' }}>
        <Col span={12}>
          <Row>
            <h1 className={styles.cutTitle}>
              Tagbot helps you to manage
              <br />
              your AWS resources
            </h1>
          </Row>
          <Row>
            <span className={styles.cutDesc}>
              Spend more time on what your business
              <br />
              was created to do
            </span>
          </Row>
        </Col>
        <Col span={12}>
          <img
            className={styles.imgPresentation}
            src={screenshotMain}
            alt={'AWS Tagging Dashboard'}
          />
        </Col>
      </Row>
      <div className={styles.cutAccent} />
    </Content>
  );
}
