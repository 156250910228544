import React from 'react';
import { Layout } from 'antd';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CutPricing from '../components/CutPricing';
import FeaturesPreview from '../components/FeaturesPreview';
import Cta from '../components/Cta';
import Newsletter from '../components/Newsletter';
import Nav from '../components/Nav';

export default function Pricing() {
  return (
    <Layout>
      <Header />
      <>
        <CutPricing />
        <FeaturesPreview />
        <Cta />
        <Newsletter />
        <Nav />
      </>
      <Footer />
    </Layout>
  );
}
